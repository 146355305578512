<!--
 * @Description: 登录页
 * @Author: luocheng
 * @Date: 2021-10-25 10:26:07
 * @LastEditors: luocheng
 * @LastEditTime: 2022-05-24 09:03:24
-->
<template>
  <div class="login">
   <article class="login-main">
     <section class="left-main">
      <!-- <div class="logo-box">
        <img src="@/assets/images/nanhua_logo_new.png" alt="logo" class="logo">
      </div> -->
      <div class="title-box">
        <!-- <img src="@/assets/images/login/title.png" alt="广州南华BIM+项目建管平台" class="title"> -->
        <h2 class="title">BIMCC数字建造平台</h2>
      </div>
      <article class="login-content">
        <!-- 验证码登录 -->
        <LoginForm></LoginForm>
      </article>
     </section>
     <section class="diamond">
       <img src="@/assets/images/login/diamond-2.png" class="diamond-img" alt="介绍">
     </section>
   </article>
  </div>
</template>

<script>
import LoginForm from '../components/LoginForm';

export default {
  name: 'Login',
  components: {
    LoginForm
  }
}
</script>

<style lang="less" scoped>
.login{
  height: 100vh;
  width: 100vw;
  min-width: 860px;
  min-height: 800px;
  background: #fff;
  background-image: url('~@/assets/images/login/login_bg_1.jpeg');
  background-repeat: no-repeat;
  background-clip: border-box;
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
  .login-main{
    box-sizing: border-box;
    padding: 10vh 7vw;
    min-width: 860px;
    min-height: 800px;
    overflow: hidden;
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    background-image: url('~@/assets/images/login/content_bg_1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    .left-main{
      flex: 1;
      .logo-box{
        height: 77px;
        width: 86px;
        position: relative;
        // background: red;
        overflow: hidden;
        .logo{
          // height: 1080px;
          // width: 1920px;
          // position: absolute;
          // display: block;
          // object-fit: cover;
          // top: -113px;
          // left: -122px;
          width: 100%;
        }
      }
      .title-box{
        position: relative;
        width: 520px;
        height: 110px;
        overflow: hidden;
        margin-top: 60px;
        .title{
          // position: absolute;
          // display: block;
          // width: 1080px;
          // top: -70px;
          // left: -10px;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 42px;
          color: #0070BB;
        }
      }
      .login-content{
        height: 467px;
        width: 520px;
        background-image: url('~@/assets/images/login/form_bg.png');
        background-size: 100% 100%;
        box-sizing: border-box;
        padding: 50px 60px;
      }
    }
    .diamond{
      position: relative;
      display: inline-block;
      // width: 840px;
      width: 43%;
      height: 458px;
      margin-top: 120px;
      margin-left: 20px;
      .diamond-img{
        position: absolute;
        display: block;
        // width: 980px;
        width: 146%;
        left: -152px;
        top: -150px;
        object-fit: cover;
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .login{
    .login-main{
      .diamond{
        .diamond-img{
          top: -70px;
          left: -100px;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .login{
    .login-main{
      .diamond{
        .diamond-img{
          // top: -60px;
          top: -15px;
          left: -60px;
        }
      }
    }
  }
}
@media screen and (max-width: 860px) {
  .login{
    .login-main{
      padding-left: 65px;
      padding-right: 55px;
      .diamond{
        // width: 800px;
        .diamond-img{
          top: 70px;
          left: -30px;
        }
      }
    }
  }
}
@media screen and (max-height: 800px) {
    .login{
    .login-main{
      padding-top: 78px;
      padding-bottom: 78px;
    }
  }
}

</style>
