/*
 * @Description: 登录
 * @Author: luocheng
 * @Date: 2022-03-21 15:41:38
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-29 11:35:16
 */
import { dataInterface } from '@/apis/data/index';
import { setToken } from '@/utils/tools';

export default {
  methods: {
    /**
     * @desc: 执行登录
     * @param {Object} payload 参数
     */
    doLogin(payload) {
      // console.log(payload, dataInterface)
      dataInterface({
        __method_name__: 'globalFunctionCall',
        typeName: 'LoginFuncClass',
        type: 'behavior',
        funcName: 'LoginBehavior',
        payload
      })
        .then((res) => {
          // console.log(res, '=======');
          if (!res || res.status !== 200 || !res.data || res.data.code !== 200 ||!res.data.data) {
            this.$loading().close();
            // axios 拦截里面已经有提示了，不要重复提示
            // this.$message.error(res.data.msg || '登录失败!');
            return;
          }
          this.$message.success('登录成功!');
          const userInfo = res.data.data;
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          setToken(userInfo.token || '');
          const pageData = JSON.parse(sessionStorage.getItem('pageData'));
          const entryUUID = 'field61654a21e5ae7';
          const pathUUID = '7c5166f1-84aa-4639-83f6-7a2a07a518d9';
          const pageUUID = '532ca44e-18f5-4a0d-96d2-11bfd1b68992';
          const pathObj = this.getEntryRoute(entryUUID, pageData);
          const routeObj = {
            path: (pathObj && pathObj[pathUUID]) || process.env.VUE_APP_HOME_PATH,
            query: {
              pageId: pathObj ? pathObj[pageUUID] : '',
              pageUUID: pathObj ? pathObj['field61b95865f2ccd'] : ''
            }
          };
          sessionStorage.setItem('indexPath', JSON.stringify(routeObj));
          localStorage.setItem('updatePwd',res.data.data.is_update_pwd)
          if(res.data.data.is_update_pwd == 0){
            this.$router.replace({
              name: 'PedestalUserCenter',
              query: {
                forcedPassword: 1
              }
            });
          }else{
            this.$router.replace({
              name: 'SecondScreen'
            });
          }
         
          this.$loading().close();
        })
        .catch((err) => {
          console.log(err, '----err');
          this.$loading().close();
          this.$message.error('登录失败!');
        });
    },
    /**
		 * @desc: 获取入口路由
		 * @param {String} entryUUID 入口字段
		 * @param {Object} pageData 页面数据
		 */
		getEntryRoute(entryUUID, pageData) {
			if (!entryUUID || !Array.isArray(pageData) || !pageData.length) {
				return null;
			}
			let result = null;
			for (let i = 0; i < pageData.length; i++) {
				const item = pageData[i];
				if (+item['field61654a21e5ae7'] === 1) {
					result = item;
					break;
				}
				if (
					item[this.typeUUID] === 2 &&
					item['72da4bd8-c508-48c9-8e75-5ed59e6b09d2'] &&
					item['72da4bd8-c508-48c9-8e75-5ed59e6b09d2'].length
				) {
					result = this.getEntryRoute(
						entryUUID,
						item['72da4bd8-c508-48c9-8e75-5ed59e6b09d2']
					);
					if (result) {
						break;
					}
				}
			}
			return result;
		}
  }
}