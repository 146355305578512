<!--
 * @Description: 短信验证码登录
 * @Author: luocheng
 * @Date: 2021-10-25 11:59:42
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-27 11:17:00
-->
<template>
	<div class="login-form">
		<h4 class="label">用户登录</h4>
		<el-form
			class="form"
			:model="loginForm"
			ref="loginForm"
			:rules="rules"
		>
			<!-- 账号密码登录 -->
			<template v-if="loginWay === 1">
				<el-form-item prop="userName">
					<el-input
						v-model="loginForm.userName"
						placeholder="请输入用户名"
						clearable
						prefix-icon="el-icon-user-solid"
					>
					</el-input>
				</el-form-item>
				<el-form-item prop="password">
					<el-input
						v-model="loginForm.password"
						placeholder="请输入密码"
						clearable
						show-password
						@enter="onLogin"
					>
						<span
							slot="prefix"
							class="prefix"
						>
							<i class="iconfont iconsuoding"></i>
						</span>
					</el-input>
				</el-form-item>
			</template>
			<template v-else>
				<el-form-item prop="phoneNumber">
					<div class="code-item">
						<el-input
							v-model="loginForm.phoneNumber"
							placeholder="请输入手机号"
							clearable
							prefix-icon="el-icon-user-solid"
						></el-input>
						<a
							href="javascript::"
							class="get-code"
							@click="onGetCode"
							:class="{
                'disabled': +codeStatus === 1 || +codeStatus === 3
              }"
						>
							<!--  验证码获取状态 0 从未获取 1 获取中 2  已获取已过期(重新获取) 3 已获取未过期(倒计时) -->
							<template v-if="+codeStatus === 0">获取验证码</template>
							<template v-else-if="+codeStatus === 1">获取中...</template>
							<template v-else-if="+codeStatus === 2">重新获取</template>
							<template v-else-if="+codeStatus === 3">{{ countDown }}秒后可重发</template>
						</a>
					</div>
				</el-form-item>
				<el-form-item prop="code">
					<el-input
						v-model="loginForm.code"
						placeholder="请输入验证码"
						clearable
						@enter="onLogin"
					>
						<span
							slot="prefix"
							class="prefix"
						>
							<i class="iconfont iconsuoding"></i>
						</span>
					</el-input>
				</el-form-item>
			</template>
			<el-form-item class="login-item">
				<a
					href="javascript:;"
					class="login-btn"
					@click="onLogin"
				>登录</a>
			</el-form-item>
			<a
				href="javascript:;"
				class="change-way"
				@click="onChangeWay"
			>切换登录方式</a>
		</el-form>
	</div>
</template>

<script>
import { Form, FormItem, Input } from 'element-ui';
import { dataInterface } from '@/apis/data/index';
import mixin from '../mixin';

export default {
	name: 'LoginForm',
	mixins: [mixin],
	components: {
		'el-form': Form,
		'el-form-item': FormItem,
		'el-input': Input
	},
	data() {
		const phoneReg =
			/^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(19[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
		/* eslint-disable */
		const checkPhone = (rule, value, callback) => {
			if (!value) {
				return callback(new Error('请输入手机号码!'));
			}
			if (!phoneReg.test(value)) {
				return callback(new Error('请输入正确格式的手机号码!'));
			}
			callback();
		};
		return {
			loginForm: {
				userName: '', // 用户名
				phoneNumber: '', // 手机号
				password: '', // 密码
				code: '' // 验证码
			},
			// 登录方式 1 账号密码 0 验证码
			loginWay: 1,
			// 手机号正则
			phoneReg,
			// 验证规则
			rules: {
				userName: [
					{ required: true, message: '请输入用户名', trigger: 'blur' }
				],
				phoneNumber: [{ validator: checkPhone, trigger: 'blur' }],
				password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
				code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
			},
			// 验证码获取状态 0 从未获取 1 获取中 2  已获取已过期(重新获取) 3 已获取未过期(倒计时)
			codeStatus: 0,
			// 倒计时
			countDown: 0,
			// 结束时间戳
			endTime: 0,
			// 计时器对象
			interval: null,
			// UUID
			typeUUID: '9b251b20-6276-4db5-8f8e-4ea03e4f79c3'
		};
	},
	created() {
		this.endTime = +sessionStorage.getItem('codeTime');
		if (
			this.endTime &&
			this.endTime > 0 &&
			this.endTime > new Date().getTime()
		) {
			this.codeStatus = 3;
			this.setCountDown();
		}
	},
	mounted() {
		const that = this;
		document.onkeydown = function () {
			const key = window.event.keyCode;
			if (key == 13) {
				that.onLogin();
			}
		};
	},
	methods: {
		/**
		 * @desc: 获取验证码
		 * 验证码获取状态 0 从未获取 1 获取中 2  已获取已过期(重新获取) 3 已获取未过期(倒计时)
		 */
		onGetCode() {
			if (this.codeStatus === 1) {
				this.$message.warning('验证码获取中...');
				return;
			}
			if (this.codeStatus === 3) {
				this.$message.warning('请勿重复获取验证码!');
				return;
			}
			if (
				!this.loginForm.phoneNumber ||
				!this.phoneReg.test(this.loginForm.phoneNumber)
			) {
				this.$message.error('请输入正确格式的手机号码！');
				return false;
			}
			this.codeStatus = 1;
			// console.log('获取');
			dataInterface({
				__method_name__: 'globalFunctionCall',
				typeName: 'LoginFuncClass',
				type: 'behavior',
				funcName: 'SendLoginSms',
				payload: {
					phone: this.loginForm.phoneNumber
				}
			})
				.then((res) => {
					if (res && res.status === 200) {
						this.$message.success(res.data.data.msg);
						this.codeStatus = 3;
						this.endTime = new Date().getTime() + 60000 * 5;
						this.setCountDown();
						sessionStorage.setItem('codeTime', this.endTime);
					}
				})
				.catch((err) => {
					console.log(err);
					this.$message.error('验证码获取失败！');
					this.codeStatus = 0;
				});
		},
		/**
		 * @desc: 倒计时
		 */
		setCountDown() {
			const now = new Date().getTime();
			if (this.endTime && now > this.endTime) {
				this.codeStatus = 2;
				return;
			}
			this.countDown = Math.floor((this.endTime - now) / 1000);
			this.interval = setInterval(() => {
				if (this.countDown <= 0) {
					this.codeStatus = 2;
					clearInterval(this.interval);
					sessionStorage.removeItem('codeTime');
					return;
				}
				this.countDown--;
			}, 1000);
		},
		/**
		 * @desc: 切换登录方式
		 */
		onChangeWay() {
			this.loginWay = this.loginWay ? 0 : 1;
			this.loginForm = {
				...this.loginForm,
				password: '',
				code: ''
			};
			this.$refs.loginForm.resetFields();
		},
		/**
		 * @desc: 登录
		 */
		onLogin() {
			this.$refs.loginForm.validate((valid) => {
				if (!valid) return;
				this.$loading();
				let payload = null;
				if (this.loginWay === 1) {
					// 账号密码
					payload = {
						password: this.loginForm.password,
						name: this.loginForm.userName
					};
				} else {
					// 验证码
					payload = {
						phone: this.loginForm.phoneNumber,
						code: this.loginForm.code,
						loginType: 'phone'
					};
				}
				this.doLogin(payload);
			});
		},
		/**
		 * @desc: 根据对象值获取uuid
		 * @param {String} value 值
		 * @param {Object} obj 对象
		 * @return {String} key
		 */
		getUUIDByValue(value, obj) {
			for (let key in obj) {
				if (obj[key] === value) {
					return key;
				}
			}
		},
	},
	beforeDestroy() {
		if (this.interval && typeof this.interval === 'object') {
			clearInterval(this.interval);
		}
	}
};
</script>

<style lang="less" scoped>
@inputHeight: 50px;
@baseFont: 16px;
@baseColor: rgb(105, 138, 179);
@baseBg: rgb(236, 242, 252);
.login-form {
	height: 100%;
	width: 100%;
	overflow: hidden;
	.label {
		box-sizing: border-box;
		width: 100%;
		padding: 30px 0;
		text-align: center;
		font-size: 24px;
		line-height: 24px;
		font-family: 'PingFang SC';
		color: rgba(20, 29, 97, 1);
	}
	:deep(.el-form)  {
		.el-input {
			height: @inputHeight;
			background: @baseBg;
			&.el-input--small {
				font-size: @baseFont;
				.el-input__inner {
					height: @inputHeight;
					padding-left: 35px;
					line-height: @inputHeight;
					background: @baseBg;
					border: none;
					outline: none;
				}
			}
			.el-input__prefix {
				font-size: 18px;
				line-height: @inputHeight;
				color: rgba(73, 177, 254);
				padding-right: 20px;
			}
			input::-webkit-input-placeholder {
				color: @baseColor;
				font-size: @baseFont;
			}
			input::-moz-input-placeholder {
				color: @baseColor;
				font-size: @baseFont;
			}
			input::-ms-input-placeholder {
				color: @baseColor;
				font-size: @baseFont;
			}
		}
		.login-btn {
			display: block;
			outline: none;
			height: @inputHeight;
			line-height: @inputHeight;
			letter-spacing: 5px;
			width: 100%;
			border-radius: 6px;
			background: rgba(73, 177, 254);
			color: rgba(1, 30, 63, 1);
			font-size: 18px;
			font-weight: 600;
			text-align: center;
		}
		.el-form-item {
			margin-bottom: 25px;
			&.login-item {
				margin-bottom: 0;
			}
			.code-item {
				display: flex;
				.el-input {
					flex: 1;
					margin-right: 20px;
				}
				.get-code {
					height: @inputHeight;
					line-height: calc(@inputHeight - 20px);
					width: auto;
					min-width: 120px;
					background: @baseBg;
					border-radius: 4px;
					box-sizing: border-box;
					padding: 10px 10px;
					color: @baseColor;
					font-size: @baseFont;
					text-align: center;
					&.disabled {
						background: #dcdfe6;
						color: #fff;
						cursor: not-allowed;
					}
				}
			}
		}
		.change-way {
			display: block;
			width: 7em;
			text-align: left;
			font-size: @baseFont;
			padding: 20px 0;
			color: rgb(138, 164, 212);
		}
	}
	.prefix {
		.iconfont {
			padding-left: 5px;
			font-size: 16px;
		}
	}
}
</style>