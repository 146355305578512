<!--
 * @Description: 登录页
 * @Author: luocheng
 * @Date: 2021-10-25 10:26:07
 * @LastEditors: zx
 * @LastEditTime: 2022-07-01 14:03:09
-->
<template>
  <div class="login-index">
   <!-- 葛洲坝 -->
   <GZBIndex v-if="projectEnvironment === 'gzb'"></GZBIndex>
   <!-- 南华 -->
   <NHLogin v-else-if="projectEnvironment === 'nh'"></NHLogin>
   <!-- 濮阳工学院 -->
   <PYGXYLogin v-else-if="projectEnvironment === 'pygxy'"></PYGXYLogin>
   <!-- 中设项目 -->
   <ZSSJZXLogin v-else-if="projectEnvironment === 'zssjzx'"></ZSSJZXLogin>
   <!-- dev -->
   <DevLogin v-else></DevLogin>
  </div>
</template>

<script>
import NHLogin from './nh/Index';
import GZBIndex from './gzb/Index';
import DevLogin from './dev/Index.vue';
import PYGXYLogin from './pygxy/Index';
import ZSSJZXLogin from './zssjzx/Index';

import { mapState } from 'vuex';

export default {
  name: 'Login',
  components: {
    NHLogin,
    GZBIndex,
    DevLogin,
    PYGXYLogin,
    ZSSJZXLogin
  },
  computed: {
    ...mapState(['projectEnvironment'])
  }
}
</script>

<style lang="less" scoped>
.login-index{
  height: 100vh;
  width: 100vw;
  min-width: 860px;
  min-height: 800px;
  background: #fff;
  overflow: hidden;
  position: relative;
}
</style>